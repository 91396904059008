<template>
  <v-row>
    <v-col cols="12">
      <AlertBar prominent voltar type="error" :item="item" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "NotFound",
  props: ["item"]
};
</script>

<style></style>
